@import '../../../assets/styling.scss';
.single-table {
  padding: 2.5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white !important;
  text-align: center;
  color: $fontColorVAlue !important;
  white-space: nowrap;
  transition: transform 0.2s ease-in-out;

  &.active {
    background-color: $selectedtabbgcolor !important;
    color: $selectedtabfontcolor !important;

    .MuiTab-root {
      color: $bgColorValue !important;

      .MuiSvgIcon-root {
        fill: $bgColorValue !important;
      }
    }
  }

  .MuiTab-root {
    min-width: auto;
    overflow-x: auto;
    flex-direction: row;
    text-transform: none;
    height: 10px;

    &.active {
      color: $bgColorValue !important;

      .MuiSvgIcon-root {
        fill: $bgColorValue !important;
      }
    }
  }
}
.SingleTableTab{
  min-width: auto !important;
  overflow-x: auto !important;
  flex-direction: row !important;
  text-transform: none !important;
  height: 10px !important;
  font-size: $tablepagefontsize !important;
  
}
