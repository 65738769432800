.row-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60vw;
  max-height: 80%;
  background-color: var(--mui-background-paper, #fff);
  border-radius: 5px;
  box-shadow: 24px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
}

.row-data-heading-container {
  display: flex;
  justify-content: center; 
  align-items: flex-start; 
  width: 100%; 
  margin-top: 10px;
}

.row-data-heading {
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

.row-popup-attachment-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  gap: 8px;
  min-height: 40px;
  max-height: 40px;
  width: 96%;
  overflow-y: hidden; 
  white-space: nowrap;
  margin-top: 16px; 
  margin-bottom: 8px; 
}

.row-popup-attachment-img {
  width: 60px;
  height: 40px;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.row-popup-input-container {
  width: 100%;
  .MuiInputLabel-root {
      font-weight: bold;
  }
  textarea{
    overflow: auto !important;
  }
}
